import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import VersionTable from 'components/Software/macOS/InstarVision/About/versionTable';
import NavButtons from 'components/Software/macOS/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for macOS",
  "path": "/Software/macOS/InstarVision/About/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "Information about your software installation.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='InstarVision v3 for macOS' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/macOS/InstarVision/About/' locationFR='/fr/Software/macOS/InstarVision/About/' crumbLabel="About" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e63889602fad2d4370c59a356ac6b59/29114/Banner_en-InstarVision2-0_About.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAdklEQVQI143HMQ6CMBhA4f8qeD4GNozsDrRONW6wyFVcXAmBxBPoSJGmNOSRyKKL4SXf8ITZg7PgevBvGPv1CWzNGMP+kEFwiKotuhnRXfihugnV+r/y1nN6zGRHTZrE5LVFpHgi5QupLHL9Um01sDvfiC73zy/iccD60IZ8zAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e63889602fad2d4370c59a356ac6b59/e4706/Banner_en-InstarVision2-0_About.avif 230w", "/en/static/4e63889602fad2d4370c59a356ac6b59/d1af7/Banner_en-InstarVision2-0_About.avif 460w", "/en/static/4e63889602fad2d4370c59a356ac6b59/7f308/Banner_en-InstarVision2-0_About.avif 920w", "/en/static/4e63889602fad2d4370c59a356ac6b59/e1c99/Banner_en-InstarVision2-0_About.avif 1380w", "/en/static/4e63889602fad2d4370c59a356ac6b59/76ea5/Banner_en-InstarVision2-0_About.avif 1840w", "/en/static/4e63889602fad2d4370c59a356ac6b59/f696c/Banner_en-InstarVision2-0_About.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e63889602fad2d4370c59a356ac6b59/a0b58/Banner_en-InstarVision2-0_About.webp 230w", "/en/static/4e63889602fad2d4370c59a356ac6b59/bc10c/Banner_en-InstarVision2-0_About.webp 460w", "/en/static/4e63889602fad2d4370c59a356ac6b59/966d8/Banner_en-InstarVision2-0_About.webp 920w", "/en/static/4e63889602fad2d4370c59a356ac6b59/445df/Banner_en-InstarVision2-0_About.webp 1380w", "/en/static/4e63889602fad2d4370c59a356ac6b59/78de1/Banner_en-InstarVision2-0_About.webp 1840w", "/en/static/4e63889602fad2d4370c59a356ac6b59/882b9/Banner_en-InstarVision2-0_About.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e63889602fad2d4370c59a356ac6b59/81c8e/Banner_en-InstarVision2-0_About.png 230w", "/en/static/4e63889602fad2d4370c59a356ac6b59/08a84/Banner_en-InstarVision2-0_About.png 460w", "/en/static/4e63889602fad2d4370c59a356ac6b59/c0255/Banner_en-InstarVision2-0_About.png 920w", "/en/static/4e63889602fad2d4370c59a356ac6b59/b1001/Banner_en-InstarVision2-0_About.png 1380w", "/en/static/4e63889602fad2d4370c59a356ac6b59/161ec/Banner_en-InstarVision2-0_About.png 1840w", "/en/static/4e63889602fad2d4370c59a356ac6b59/29114/Banner_en-InstarVision2-0_About.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e63889602fad2d4370c59a356ac6b59/c0255/Banner_en-InstarVision2-0_About.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---about",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---about",
        "aria-label": "side navigation   about permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - About`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d84de653365bdf1e4207d19fc7f6d042/c929c/de_aboutIV.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADTklEQVQozyWQ/zPbBwBAP//V6Ea37pZUTU1CQsgEQXQT7ZLF10kilRF6J0GTYnFTESGthppOL0rboMGlnSLlWO3W2rJs2Iw659627od377f3wxMSzqWTdCGbpJTst06+kENyqgJxhpI0STbitEwSxZkknpeR8B9iGQkpct5NyyU5NQtVaTlrKysM+XxsbmwgqHo3UbmjKHtWUXY/R975DFl7hJyOCJ92himyf09u+wxyx0OyHI+QucLkuObIuzGLomuOz90zDHq92O12xkZHETSN3RgHHmMNPMV8ZxlTYJXA3Botd59jGF6h8dYiVb4IupshdAPzVPRMoveEqBkO0xDcpmn8B9xuNz6fD6/Xi6DXl9HhbKejy0lzh4uJYJDfXkVZjIS5GZjA1nsHm+8Bdc5Batu+oURXg77JjrXXj80/jbVnkHaHA6fTSa/bjVBptuC5PUa3dwSnZ4T+kQnujgf4tu8GrsEx8gxtqM3dFFU2ka+tI0drRNvoQGftJNPgQK5rxtPfj3fAi394GKH0cjXllfVcrrPyhamVCmMLpqZr5JXp0VQ3c76knsScLzkjuURCqgpR4VeI1GY+KjJytsBEblU7u3/EicV+4fDvQ4SB+wv4ggv4pxYITC/x3cMlAsF5psPPmHz0hPxSLWqNlupaIw0NFixmM8Z6EwZDLbLcAjRaPa9f7ROLHXB0eIgwu/4rTzZiRHf+Iry+w+zqT9xfjLL842si69u0XbPgsNXQ12VjPuhla3mSyONbjPpcpGdmYrGY+D0Ou7twfPwG4cXWNsvrW+zEYgRmlrjU1Eez+zbG614c/QFq9YWUFErIUmYz4m/j5eYUoaCLGX81coWUq8ZyYrE94vFd9vf3EDyj03T7g/TdW8DS/4B0gwuJ3o602klxo5uzIhHvvP8hYpmCrAIV8gI1UmUeZRoVn8hlFJWq+XN/j4ODg/8fVrV6MNS1UnHVjW1oluv3VmgZCvG1L4TVM0WiSMIZkZRzUgVpucUoS67wWUU5V3TlfPDxRaSKPE5OTjg9PeXN0RHCvfBLxkNRJufWmF3dIbwRZz4aY/5FnKmnPyPKyCdJLCExJQN5fiHFZRpUxWpk+SreE6VwMSub4+Pjt9Gjf4P/AGSFlTm1WJKMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d84de653365bdf1e4207d19fc7f6d042/e4706/de_aboutIV.avif 230w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/d1af7/de_aboutIV.avif 460w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/7f308/de_aboutIV.avif 920w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/203bb/de_aboutIV.avif 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d84de653365bdf1e4207d19fc7f6d042/a0b58/de_aboutIV.webp 230w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/bc10c/de_aboutIV.webp 460w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/966d8/de_aboutIV.webp 920w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/491b1/de_aboutIV.webp 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d84de653365bdf1e4207d19fc7f6d042/81c8e/de_aboutIV.png 230w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/08a84/de_aboutIV.png 460w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/c0255/de_aboutIV.png 920w", "/en/static/d84de653365bdf1e4207d19fc7f6d042/c929c/de_aboutIV.png 1218w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d84de653365bdf1e4207d19fc7f6d042/c0255/de_aboutIV.png",
              "alt": "InstarVision v3 for macOS",
              "title": "InstarVision v3 for macOS",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <VersionTable mdxType="VersionTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      